<template>
  <div class="landInfoForm-container">
    <form-panel class="form-container" ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <col2-detail>
        <col2-block title="楼宇基础信息">
          <el-form-item label="所属项目" :rules="[{ required: true, message: '请选择所属项目', trigger: 'blur' }]" prop="communityId">
            <!-- <v-select2 :width="200" v-model="form.communityId" v-bind="communityParams" placeholder="查询项目"/> -->
            <div v-text="form.communityName"></div>
          </el-form-item>
          <el-form-item label="楼宇名称" :rules="[{ required: true, message: '请输入楼宇名称', trigger: 'blur' }]" prop="buildingName">
            <!-- <v-input placeholder="请输入楼宇名称" v-model.trim="form.buildingName"></v-input> -->
            <div v-text="form.buildingName"></div>
          </el-form-item>
          <el-form-item label="楼宇编号" :rules="[{ required: true, message: '请输入楼宇编号', trigger: 'blur' }]" prop="buildingSort">
            <v-input-number placeholder="请输入楼宇编号" :min="0" :max="99999" v-model.trim="form.buildingSort"></v-input-number> <span> (影响业主房号前端排序)</span>
          </el-form-item>
          <el-form-item label="建筑类型" :rules="[{ required: true, message: '请选择建筑类型', trigger: 'blur' }]" prop="architectureType">
            <v-select v-model="form.architectureType" :options="architectureType"></v-select>
          </el-form-item>
          <el-form-item label="楼宇类型" :rules="[{ required: true, message: '请选择楼宇类型', trigger: 'blur' }]" prop="buildingType">
            <v-select v-model="form.buildingType" :options="buildingType"></v-select>
          </el-form-item>
          <el-form-item label="楼宇状态" :rules="[{ required: true, message: '请选择楼宇状态', trigger: 'blur' }]" prop="buildingStatus">
            <v-select v-model="form.buildingStatus" :options="buildingStatus"></v-select>
          </el-form-item>
          <el-form-item label="楼层数" :rules="[{ required: true, message: '请输入楼层数', trigger: 'blur' }]" prop="buildingNum">
            <v-input-number placeholder="请输入楼层数" :min="1" :max="99" v-model="form.buildingNum"></v-input-number> 层 / 楼
          </el-form-item>
          <el-form-item label="梯户比" :rules="[{ required: true, message: '请选择梯户比', trigger: 'blur' }]" prop="ladderThanFamily">
            <v-select v-model="form.ladderThanFamily" :options="ladderThanFamilyType"></v-select>
          </el-form-item>
          <el-form-item label="装修类型" :rules="[{ required: true, message: '请选择装修类型', trigger: 'blur' }]" prop="decorateType">
            <v-select v-model="form.decorateType" :options="decorateType"></v-select>
          </el-form-item>
          <el-form-item label="交付情况" :rules="[{ required: true, message: '请选择交付情况', trigger: 'blur' }]" prop="deliverySituation">
            <v-select v-model="form.deliverySituation" :options="deliverySituationType"></v-select>
          </el-form-item>
          <el-form-item label="交付时间" :rules="[{ required: true, message: '请选择交付时间', trigger: 'blur' }]" prop="deliveryTime">
            <v-datepicker v-model="form.deliveryTime" type="data"></v-datepicker>
          </el-form-item>

          <el-form-item label="建筑面积" prop="coveredArea">
            <!-- <v-input-number placeholder="请输入建筑面积" v-model="form.coveredArea"></v-input-number> 平方米 -->
            <span v-text="form.coveredArea"></span> <span v-if="form.coveredArea"> m&sup2; </span>
          </el-form-item>
          <el-form-item label="地下室面积" prop="basementArea">
            <!-- <v-input-number placeholder="请输入地下室面积" v-model="form.basementArea"></v-input-number> 平方米 -->
            <span v-text="form.basementArea"></span> <span v-if="form.basementArea"> m&sup2; </span>
          </el-form-item>
          <el-form-item label="统计面积" prop="statisticalArea">
            <!-- <v-input-number placeholder="请输入统计面积" v-model="form.statisticalArea"></v-input-number> 平方米 -->
            <span v-text="form.statisticalArea"></span> <span v-if="form.statisticalArea"> m&sup2; </span>
          </el-form-item>
          <el-form-item class="posStyle" label="经纬度" prop="longitude">
            <v-input class="area-item" v-model="form.longitude" placeholder="经度" :width="width"></v-input>
            <v-input class="area-item" v-model="form.latitude" placeholder="纬度" :width="width"></v-input>
            <v-button text="地图标注" @click="getMap"></v-button>
          </el-form-item>
          <el-form-item label="上传附件" :rules="[{ required: false, message: '请输入地块代码', trigger: 'blur' }]" prop="tableData">
            <v-button
              text="新增"
              @click="upload"
            ></v-button>
            <el-table
              v-if="tableData.length"
              class="table"
              :data="tableData"
              border
              style="width: 70%">
              <el-table-column
                label="序号"
                type="index">
              </el-table-column>
              <el-table-column
                prop="fileName"
                label="文件名称">
              </el-table-column>
              <el-table-column
                prop="uploadTime"
                label="上传时间"
                width="180">
              </el-table-column>
              <el-table-column
                label="操作"
                width="100">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="handleDelete(scope.row, scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          
        </col2-block>
      </col2-detail>
    </form-panel>
    <v-map
      :selectedLng="Number(form.longitude)"
      :selectedLat="Number(form.latitude)"
      :showMapDialog.sync="showMapDialog"
      @setLngAndLat="setLngAndLat"
    ></v-map>
    <el-dialog title="上传附件" width="600px" :visible.sync="showUploadFile" @closed="handleCloseUpload">
      <!-- <v-uploader
        ref="myUpload"
        name="files"
        :action="uploadUrl"
        :fileList.sync="fileList"
        :acceptFileType="supportFileType"
        :onlyImage="false"
        :onSuccess="handleUploadSucc"
      >
        <div slot="tip">只能上传.xls,.doc,.docx,.pdf,.png,.jpg,.txt,.xlsx文件，且文件不超过200M</div>
      </v-uploader> -->
      <v-uploader2
        class="upload2"
        :action="uploadUrl"
        filePath="customerFiles"
        v-model="fileList"
        :limit="100"
        tip="附件类型：.xls, .doc, .docx, .pdf, .png, .jpg, .txt, .xlsx，且文件不超过200M"
        :beforeRemove="beforeRemove"
      />
      <div class="config-btns-wrapper">
        <v-button text="保存" type="primary" @click="handleUploadConfirm"></v-button>
        <v-button text="取消" type="default" class="my-cancel-btn" @click="showUploadFile = false"></v-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { uploadURL } from 'common/api'
import _ from 'lodash'
import { landType, architectureType, buildingType, buildingStatus, ladderThanFamilyType, decorateType, deliverySituationType } from './map'
import { Col2Detail, Col2Block, CheckboxPlus, MultiSelect } from 'components/bussiness'
import { vMap, vUploader, vUploader2 } from 'components/control'
import { communityParams } from 'common/select2Params'
import { uploadImageURL,uploadURL, updateBuildingURL, getBaseBuildingInfoURL } from './api'
export default {
  name: 'landInfo',
  components: {
    CheckboxPlus,
    MultiSelect,
    Col2Detail,
    Col2Block,
    vMap,
    vUploader,
    vUploader2
  },
  data () {
    return {
      communityParams,
      architectureType,
      deliverySituationType,
      decorateType,
      ladderThanFamilyType,
      buildingType,
      buildingStatus,
      uploadUrl: uploadURL,
      lwidth: 300,
      width: 150,
      personHead: [],
      supportFileType: '.xls,.doc,.docx,.pdf,.png,.jpg,.txt,.xlsx',
      form: {
        communityId: '',
        buildingName: '',
        buildingSort: '',
        architectureType: undefined,
        buildingType: undefined,
        buildingStatus: undefined,
        buildingNum: undefined,
        ladderThanFamily: undefined,
        decorateType: undefined,
        deliverySituation: undefined,
        deliveryTime: '',
        coveredArea: '',
        basementArea: '',
        statisticalArea: '',
        longitude: '',
        id: '',
        latitude: '',
        communityName: '',
        annexVList: []
      },
      submitConfig: {},
      submitUrl: updateBuildingURL,
      fileList: [],
      showMapDialog: false,
      landType,
      showUploadFile: false,
      tableData: [],
      nodeId: '',
      communityId: '',
      communityName: '',
      buildingName: '',
      statisticalArea: '',
      basementArea: '',
      coveredArea: '',
    }
  },
  watch: {
    fileList (ne, ol) {
      // console.log(ne);
      this.handleUploadSucc2(ne)
    }
  },
  mounted () {
    // const nodeId = this.$route.query.nodeId || ''
    const { 
      nodeId,
      communityName,
      communityId,
      blockBuildingName,
      statisticalArea, // 统计面积
      totalArea, // 建筑面积
      undergroundArea, // 地下室面积
    } = this.$route.query
    this.nodeId = nodeId || ''
    this.communityId = communityId
    this.communityName = communityName
    this.buildingName = blockBuildingName
    this.statisticalArea = statisticalArea
    this.basementArea = undergroundArea
    this.coveredArea = totalArea
    if (nodeId) {
      this.handleGetBuildingInfo(nodeId)
    }
  },
  methods: {
    submitBefore (data) {
      if (!data.id) {
        data.id = this.nodeId
      }
      // if (!data.annexVList) {
      data.annexVList = []
      // }
      this.tableData && this.tableData.map(item => {
        data.annexVList.push({annex: item.url || item.annex, uploadTime: item.uploadTime, fileName: item.fileName || ''})
      })
      return data
    },
    submitSuccess (val) {
      this.$message.success('保存成功')
      this.$router.back()
    },

    getMap () {
      this.showMapDialog = true
    },
    setLngAndLat (val) {
      this.form.longitude = val.lng
      this.form.latitude = val.lat
      this.showMapDialog = false
    },

    itude (rule, value, callback) {
      if (!this.form.longitude) {
        callback(new Error('项目经度不能为空'))
      } else if (!this.form.latitude) {
        callback(new Error('项目纬度不能为空'))
      } else {
        callback()
      }
    },
    upload () {
      this.showUploadFile = true
    },
    /**
     * @description: 上传附件
     * @param {*}
     * @return {*}
     */    
    handleCloseUpload () {
      this.showUploadFile = false
    },
    async handleGetBuildingInfo (id) {
      const res = await this.$axios.get(getBaseBuildingInfoURL, { params: { id } })
      console.log(res);
      if (res.status === 100) {
        if (res.data) {
          this.form = res.data
          this.tableData = res.data.annexVList
          console.log(this.form);
        } else {
          this.form = {}
        }
        this.form.communityId = this.communityId
        this.form.communityName = this.communityName
        this.form.buildingName = this.buildingName
        this.form.statisticalArea = this.statisticalArea
        this.form.basementArea = this.basementArea
        this.form.coveredArea = this.coveredArea
      }
    },
    handleUploadSucc (data) {
      console.log(data);
      // this.form.annexList = data.data
      console.log(this.fileList);
      const time = this.handleGetTime()
      this.fileList.map((item, index) => {
        this.fileList[index].uploadTime = time
        this.fileList[index].fileName = item.name
      })
      this.tableData = [...this.tableData, ...this.fileList]
      console.log(this.tableData);
      // this.form.annexList = this.tableData
    },
    handleUploadConfirm () {
      this.showUploadFile = false
      // this.fileList = []
      // console.log(this.fileList);

      const time = this.handleGetTime()
      const list = []
      this.fileList.map((item, index) => {
        let map = {}
        const name = item.split('/').slice(-1)[0]
        map.uploadTime = time
        try {
          map.fileName = `${name.split('.')[0].slice(0, -16)}.${name.split('.')[1]}`
        } catch (err) {
          map.fileName = name
        }
        map.annex = item
        list.push(map)
        map = {}
      })
      this.tableData = [...this.tableData, ...list]
      this.fileList = []
      console.log(this.tableData);
    },
    handleDelete (row, idx) {
      console.log(row, idx);
      this.tableData.splice(idx, 1)
      // this.form.annexVList.splice(idx, 1)
    },
    // 获取当前时间
    handleGetTime () {
      let time = ''
      const year = new Date().getFullYear()
      const month = new Date().getMonth() + 1
      const date = new Date().getDate()
      time = `${year}-${month}-${date}`
      return time
    },
    /* 其余控件方法 */
    async beforeRemove() {
      // 上传文件删除前
      const isOk = await this.$confirm("此操作将永久删除该文件, 是否继续？");
      return isOk;
    },
    handleUploadSucc2 (data) {
      // // console.log(this.fileList);

      // const time = this.handleGetTime()
      // const list = []
      // data.map((item, index) => {
      //   let map = {}
      //   const name = item.split('/').slice(-1)[0]
      //   map.uploadTime = time
      //   map.fileName = name
      //   map.annex = item
      //   list.push(map)
      //   map = {}
      // })
      // this.tableData = [...this.tableData, ...list]
      // console.log(this.tableData);
    }
  }
}
</script>
<style lang="scss" scoped>
.landInfoForm-container {
  .area-item {
    margin-right: 10px;
  }
  .area-ruler {
    margin: 0 10px;
  }
  .area-bot {
    margin-bottom: 10px;
  }
  .table {
    margin-top: 10px;
  }
  ::v-deep .el-dialog__body{
    .v-uploader-wrapper {
      text-align: left;
    }
    display: flex;
    flex-direction: column;
  }
  .config-btns-wrapper {
    display: flex;
    justify-content: flex-end;
    div {
      margin-left: 20px;
    }
  }
  .upload2 {
    ::v-deep .fill-empty {
      display: none;
    }
  }
}
</style>
