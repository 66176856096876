var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "landInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            staticClass: "form-container",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "楼宇基础信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属项目",
                            trigger: "blur",
                          },
                        ],
                        prop: "communityId",
                      },
                    },
                    [
                      _c("div", {
                        domProps: {
                          textContent: _vm._s(_vm.form.communityName),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "楼宇名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入楼宇名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "buildingName",
                      },
                    },
                    [
                      _c("div", {
                        domProps: {
                          textContent: _vm._s(_vm.form.buildingName),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "楼宇编号",
                        rules: [
                          {
                            required: true,
                            message: "请输入楼宇编号",
                            trigger: "blur",
                          },
                        ],
                        prop: "buildingSort",
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          placeholder: "请输入楼宇编号",
                          min: 0,
                          max: 99999,
                        },
                        model: {
                          value: _vm.form.buildingSort,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "buildingSort",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.buildingSort",
                        },
                      }),
                      _c("span", [_vm._v(" (影响业主房号前端排序)")]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "建筑类型",
                        rules: [
                          {
                            required: true,
                            message: "请选择建筑类型",
                            trigger: "blur",
                          },
                        ],
                        prop: "architectureType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.architectureType },
                        model: {
                          value: _vm.form.architectureType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "architectureType", $$v)
                          },
                          expression: "form.architectureType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "楼宇类型",
                        rules: [
                          {
                            required: true,
                            message: "请选择楼宇类型",
                            trigger: "blur",
                          },
                        ],
                        prop: "buildingType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.buildingType },
                        model: {
                          value: _vm.form.buildingType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "buildingType", $$v)
                          },
                          expression: "form.buildingType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "楼宇状态",
                        rules: [
                          {
                            required: true,
                            message: "请选择楼宇状态",
                            trigger: "blur",
                          },
                        ],
                        prop: "buildingStatus",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.buildingStatus },
                        model: {
                          value: _vm.form.buildingStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "buildingStatus", $$v)
                          },
                          expression: "form.buildingStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "楼层数",
                        rules: [
                          {
                            required: true,
                            message: "请输入楼层数",
                            trigger: "blur",
                          },
                        ],
                        prop: "buildingNum",
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: { placeholder: "请输入楼层数", min: 1, max: 99 },
                        model: {
                          value: _vm.form.buildingNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "buildingNum", $$v)
                          },
                          expression: "form.buildingNum",
                        },
                      }),
                      _vm._v(" 层 / 楼\n        "),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "梯户比",
                        rules: [
                          {
                            required: true,
                            message: "请选择梯户比",
                            trigger: "blur",
                          },
                        ],
                        prop: "ladderThanFamily",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.ladderThanFamilyType },
                        model: {
                          value: _vm.form.ladderThanFamily,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ladderThanFamily", $$v)
                          },
                          expression: "form.ladderThanFamily",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "装修类型",
                        rules: [
                          {
                            required: true,
                            message: "请选择装修类型",
                            trigger: "blur",
                          },
                        ],
                        prop: "decorateType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.decorateType },
                        model: {
                          value: _vm.form.decorateType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "decorateType", $$v)
                          },
                          expression: "form.decorateType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "交付情况",
                        rules: [
                          {
                            required: true,
                            message: "请选择交付情况",
                            trigger: "blur",
                          },
                        ],
                        prop: "deliverySituation",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.deliverySituationType },
                        model: {
                          value: _vm.form.deliverySituation,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "deliverySituation", $$v)
                          },
                          expression: "form.deliverySituation",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "交付时间",
                        rules: [
                          {
                            required: true,
                            message: "请选择交付时间",
                            trigger: "blur",
                          },
                        ],
                        prop: "deliveryTime",
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: { type: "data" },
                        model: {
                          value: _vm.form.deliveryTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "deliveryTime", $$v)
                          },
                          expression: "form.deliveryTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "建筑面积", prop: "coveredArea" } },
                    [
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.form.coveredArea) },
                      }),
                      _vm.form.coveredArea
                        ? _c("span", [_vm._v(" m² ")])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地下室面积", prop: "basementArea" } },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.form.basementArea),
                        },
                      }),
                      _vm.form.basementArea
                        ? _c("span", [_vm._v(" m² ")])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "统计面积", prop: "statisticalArea" } },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.form.statisticalArea),
                        },
                      }),
                      _vm.form.statisticalArea
                        ? _c("span", [_vm._v(" m² ")])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "posStyle",
                      attrs: { label: "经纬度", prop: "longitude" },
                    },
                    [
                      _c("v-input", {
                        staticClass: "area-item",
                        attrs: { placeholder: "经度", width: _vm.width },
                        model: {
                          value: _vm.form.longitude,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "longitude", $$v)
                          },
                          expression: "form.longitude",
                        },
                      }),
                      _c("v-input", {
                        staticClass: "area-item",
                        attrs: { placeholder: "纬度", width: _vm.width },
                        model: {
                          value: _vm.form.latitude,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "latitude", $$v)
                          },
                          expression: "form.latitude",
                        },
                      }),
                      _c("v-button", {
                        attrs: { text: "地图标注" },
                        on: { click: _vm.getMap },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "上传附件",
                        rules: [
                          {
                            required: false,
                            message: "请输入地块代码",
                            trigger: "blur",
                          },
                        ],
                        prop: "tableData",
                      },
                    },
                    [
                      _c("v-button", {
                        attrs: { text: "新增" },
                        on: { click: _vm.upload },
                      }),
                      _vm.tableData.length
                        ? _c(
                            "el-table",
                            {
                              staticClass: "table",
                              staticStyle: { width: "70%" },
                              attrs: { data: _vm.tableData, border: "" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "序号", type: "index" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "fileName", label: "文件名称" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "uploadTime",
                                  label: "上传时间",
                                  width: "180",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "操作", width: "100" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDelete(
                                                    scope.row,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4064371640
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-map", {
        attrs: {
          selectedLng: Number(_vm.form.longitude),
          selectedLat: Number(_vm.form.latitude),
          showMapDialog: _vm.showMapDialog,
        },
        on: {
          "update:showMapDialog": function ($event) {
            _vm.showMapDialog = $event
          },
          "update:show-map-dialog": function ($event) {
            _vm.showMapDialog = $event
          },
          setLngAndLat: _vm.setLngAndLat,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传附件",
            width: "600px",
            visible: _vm.showUploadFile,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUploadFile = $event
            },
            closed: _vm.handleCloseUpload,
          },
        },
        [
          _c("v-uploader2", {
            staticClass: "upload2",
            attrs: {
              action: _vm.uploadUrl,
              filePath: "customerFiles",
              limit: 100,
              tip: "附件类型：.xls, .doc, .docx, .pdf, .png, .jpg, .txt, .xlsx，且文件不超过200M",
              beforeRemove: _vm.beforeRemove,
            },
            model: {
              value: _vm.fileList,
              callback: function ($$v) {
                _vm.fileList = $$v
              },
              expression: "fileList",
            },
          }),
          _c(
            "div",
            { staticClass: "config-btns-wrapper" },
            [
              _c("v-button", {
                attrs: { text: "保存", type: "primary" },
                on: { click: _vm.handleUploadConfirm },
              }),
              _c("v-button", {
                staticClass: "my-cancel-btn",
                attrs: { text: "取消", type: "default" },
                on: {
                  click: function ($event) {
                    _vm.showUploadFile = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }